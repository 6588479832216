import i18next from 'i18next';
import { LanguageCode } from 'dd-client/site/i18n/utils/languageCode';

const de = i18next.getFixedT(LanguageCode.DE);
const fr = i18next.getFixedT(LanguageCode.FR);

const text = {
  deDataPrivacy: de('Data privacy'),
  deImprint: de('Imprint'),
  deTermsAndConditions: de('Terms & Conditions'),
  frDataPrivacy: fr('Data privacy'),
  frImprint: fr('Imprint'),
  frTermsAndConditions: fr('Terms & Conditions'),
};

const links = {
  [LanguageCode.DE]: [
    {
      text: text.deImprint,
      url: 'https://www.brack.ch/impressum',
    },
    {
      text: text.deTermsAndConditions,
      url: 'https://www.brack.ch/unternehmen/agb',
    },
    {
      text: text.deDataPrivacy,
      url: 'https://www.brack.ch/unternehmen/datenschutz',
    },
  ],
  [LanguageCode.FR]: [
    {
      text: text.frImprint,
      url: 'https://www.brack.ch/fr/mentions-legales',
    },
    {
      text: text.frTermsAndConditions,
      url: 'https://www.brack.ch/fr/conditions-generales-de-vente',
    },
    {
      text: text.frDataPrivacy,
      url: 'https://www.brack.ch/fr/protection-des-donnees',
    },
  ],
};


export {
  links,
};
