export default Object.freeze({
  analytics: {
    componentLocation: 'partnerangebote',
    pageType: 'partnerangebote',
    url: 'https://i.partnerangebote.brack.ch/utag/competec/dealsites/prod/u.js',
    utmSource: 'partnerangebotebrack',
  },
  basicAuth: {
    enabled: false,
    users: {},
  },
  datadogLogs: {
    enabled: false,
  },
  env: 'prod',
  features: {
    capitalLetters: {
      enabled: false,
      endTime: new Date('2024-10-22T23:59:59'),
      startTime: new Date('2024-10-22T09:00:00'),
    },
    dealPageFullAnimated: {
      enabled: false,
      endTime: new Date('2023-11-23T17:45:00'),
      startTime: new Date('2023-11-23T09:00:00'),
    },
    news: false,
    newsletter: false,
    pastDeals: false,
    promotions: false,
    staticPage: false,
  },
  indexByRobots: true,
  logger: 'sentry',
  metrics: {
    enabled: true,
  },
  parent: {
    name: 'PCTIPP.CH',
    newsletterUrl: 'https://www.pctipp.ch/newsletter-2499416.html',
    url: 'https://www.pctipp.ch',
  },
  reCaptcha: {
    siteKey: '6LcfkFYqAAAAAL3GHLP-1yWMZlgtqfNx2kF7iz48',
  },
  sentry: {
    appId: '6047754',
    publicKey: 'fb80e89e7b1742ef9bae5af0d07fd640',
    sampleRate: 0.25,
  },
  siteName: 'partnerdeal',
  tenant: 'Partnerdeal',
  url: {
    api: {
      broadcast: 'https://broadcast.partnerangebote.brack.ch',
      comment: 'https://comment.partnerangebote.brack.ch/api/ui',
      deal: 'https://deal.partnerangebote.brack.ch/api/ui',
      static: 'https://static.partnerangebote.brack.ch/api/ui',
    },
    apiSsr: {
      broadcast: 'https://broadcast.int.prod.dd.competec.ninja',
      comment: 'https://comment.int.prod.dd.competec.ninja/api/ui',
      deal: 'https://deal.int.prod.dd.competec.ninja/api/ui',
      static: 'https://static.int.prod.dd.competec.ninja/api/ui',
    },
    cdn: 'https://images.partnerangebote.brack.ch/files',
    liveMessage: 'wss://live-message.partnerangebote.brack.ch',
    root: 'https://partnerangebote.brack.ch',
  },
});
